<template>
  <div>
    <b-modal
      ref="modalDataRating"
      :title="$t('Rating')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class="pl-0 pr-0 pl-sm-1 pr-sm-1"
      :visible="false"
      :ok-only="formModeView === true ? true : false"
      :ok-title="formModeView === true ? $t('Fechar') : $t('Seguinte')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="formModeView === true ? closeModal : confirmSaveModal($event)"
      @cancel="closeModal"
    >
      <vue-perfect-scrollbar
        class="b-modal-scrollarea-content"
        :settings="configSettingsScroll"
      >
        <b-container fluid>
          <b-row class="flex-sm-row-reverse">
            <b-col md="6">
              <vue-apex-charts
                ref="chartRating"
                type="radialBar"
                height="245"
                class="my-2"
                :options="goalOverviewRadialBar"
                :series="[rating.percentagem]"
              />
            </b-col>
            <b-col md="6">
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.descritivo === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.descritivo === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Descritivo') }}
                    </h6>
                    <small>{{ $t('Mais de 2000 caracteres') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.atributos === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.atributos === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Atributos') }}
                    </h6>
                    <small>{{ $t('Mais de 2 atributos opcionais') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.morada === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.morada === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Morada') }}
                    </h6>
                    <small>{{ $t('Morada exata publicada') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.imagens === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.imagens === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Fotos') }}
                    </h6>
                    <small>{{ $t('Mais de 15 fotografias') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.planta === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.planta === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Planta') }}
                    </h6>
                    <small>{{ $t('Pelo menos 1 fotografia com tag Planta') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.imagens_p === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.imagens_p === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Rotação das fotos') }}
                    </h6>
                    <small>{{ $t('Pelo menos 2 imagens principais definidas') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.videos === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.videos === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Video') }}
                    </h6>
                    <small>{{ $t('Foi adicionado pelo menos 1 vídeo') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.virtual === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.virtual === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('3D/VR') }}
                    </h6>
                    <small>{{ $t('Foi adicionado pelo menos 1 visita virtual') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                class="mb-1"
                md="12"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="rating.portais === 1 ? 'light-success' : 'light-danger'"
                    >
                      <feather-icon
                        size="18"
                        :icon="rating.portais === 1 ? 'CheckIcon' : 'XIcon'"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6
                      v-b-tooltip.hover.top="iso === 'ES' ? $t('O portal Inmovilla, Idealista e Listglobally têm de estar publicados') : $t('O portal Imovirtual, Idealista e Listglobally têm de estar publicados')"
                      class="transaction-title"
                    >
                      {{ $t('Portais') }}
                    </h6>
                    <small>{{ $t('Imóvel publicado nos portais') }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-col>
          </b-row>
        </b-container>
      </vue-perfect-scrollbar>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BMediaBody, BMedia, BMediaAside, BAvatar, VBTooltip, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    VueApexCharts,
    VuePerfectScrollbar,
    BContainer,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    rating: {
      type: Object,
      default: () => {},
    },
    formModeView: {
      type: Boolean,
      default: () => false,
    },
    iso: {
      type: String,
      default: () => 'PT',
    },
  },
  data() {
    const strokeColor = '#ebe9f1'
    const textHeadingColor = '#5e5873'
    let goalStrokeColor2 = '#51e5a8'
    let goalColor = $themeColors.success

    if (typeof this.rating.percentagem === 'undefined' || this.rating.percentagem === '' || Number(this.rating.percentagem) === 0 || Number(this.rating.percentagem) <= 69) {
      goalColor = $themeColors.danger
      goalStrokeColor2 = '#e55166'
    } else if (Number(this.rating.percentagem) >= 70 && Number(this.rating.percentagem) <= 79) {
      goalColor = $themeColors.warnig
      goalStrokeColor2 = '#e59f51'
    }

    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [goalColor],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataRating.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataRating.hide()
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async closeModal() {
      this.resolvePromise(false)
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
